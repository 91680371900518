import { Grid } from "@mui/material";
import FooterImage from "assets/images/icons/pay/footer-yellow-img.svg";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard/newPricingCard";
import { MDCustomSwitch } from "layouts/pages/pricing-page/components/Header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "utils/hooks";

const monthlyBasic = [
  { label: "Access to the dashboard and deal making tools", includes: true },
  { label: "Storage for all files and documents for each deal", includes: true },
  {
    label: "Access to the full deal strategies library",
    includes: true,
  },
  {
    label: "Deal archive of all your deals",
    includes: true,
  },
  // {
  //   label: "Utilize the pre-generated Term Sheet to built your Contract easily",
  //   includes: true,
  // },
];

const yearlyBasic = [
  // { label: "Receive full upgrades along the progress", includes: true },
  // { label: "Full Functional Online Deal Project management platform", includes: true },
  // {
  //   label: "A tool helps you to find out the opportunity and potential problem",
  //   includes: true,
  // },
  // {
  //   label:
  //     "Carefully designed parameters to lay out your condition, and make it clear to make important decisions.",
  //   includes: true,
  // },
  // {
  //   label: "Utilise the pre-generated Term Sheet to built your Contract easily.",
  //   includes: true,
  // },

  { label: "Access to the dashboard and deal making tools", includes: true },
  { label: "Storage for all files and documents for each deal", includes: true },
  {
    label: "Access to the full deal strategies library",
    includes: true,
  },
  {
    label: "Deal archive of all your deals",
    includes: true,
  },
];

const monthlyDeal = [
  { label: "Basic plus:", includes: true },
  { label: "Real time term sheet builder for all your deals", includes: true },
  {
    label:
      "Simply and easily create printable or email ready term sheets to enable faster deal making and save thousands per deal",
    includes: true,
  },
  // {
  //   label: "A tool helps you to find out the opportunity and potential problem",
  //   includes: true,
  // },
  // {
  //   label:
  //     "Carefully designed parameters to lay out your condition, and make it clear to make important decisions",
  //   includes: true,
  // },
];

const yearlyDeal = [
  // { label: "Receive full upgrades along the progress", includes: true },
  // { label: "Fully designed specified forms to generate better deals", includes: true },
  // {
  //   label: "Save more than pay in Monthly subscription",
  //   includes: true,
  // },
  // {
  //   label: "A tool helps you to find out the opportunity and potential problem",
  //   includes: true,
  // },
  // {
  //   label:
  //     "Carefully designed parameters to lay out your condition, and make it clear to make important decisions.",
  //   includes: true,
  // },
  { label: "Basic plus:", includes: true },
  { label: "Real time term sheet builder for all your deals", includes: true },
  {
    label:
      "Simply and easily create printable or email ready term sheets to enable faster deal making and save thousands per deal",
    includes: true,
  },
];

function Pricing({ isMobile }) {
  const [controller] = useMaterialUIController();
  const [isMonthly, setMonthly] = useState(false);
  const { darkMode } = controller;
  const navigate = useNavigate();
  return (
    <Grid xs={isMobile ? 12 : 8} marginX="auto" px={isMobile ? 2 : 0}>
      <MDTypography
        fontSize={40}
        fontWeight="regular"
        sx={{
          marginTop: { xs: isMobile ? "64px" : "52px", lg: "104px" },
          ...(isMobile && { marginBottom: "52px" }),
          textAlign: "center",
          color: "#282828",
        }}
      >
        Pricing
      </MDTypography>
      <MDBox zIndex={10} mt={4} px={{ xs: 1, sm: 0 }} p="0px">
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="center"
          sx={{ flexWrap: "wrap", paddingBottom: "12px", marginLeft: isMobile ? 0 : "132px" }}
        >
          <MDBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            pb="6px"
            alignItems="center"
            sx={{ alignSelf: "center" }}
          >
            <MDTypography fontWeight="normal" sx={{ color: "#0e361c" }}>
              Pay Monthly
            </MDTypography>
            <MDCustomSwitch
              onChange={(event) => setMonthly(!event.target.checked)}
              sx={{ border: "1px solid #D4AF37", borderRadius: "16px" }}
            />
            <MDTypography fontWeight="normal" sx={{ color: "#0e361c" }}>
              Pay Annually
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{
              background: "#D4AF37",
              padding: "0px 8px",
              borderRadius: "4px",
              height: "fit-content",
              marginTop: "4px",
              marginLeft: isMobile ? "auto" : "8px",
            }}
          >
            <MDTypography fontWeight="medium" fontSize="16px">
              20% discount
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            fontSize: "14px",
            color: "#004225",
            textAlign: "center",
            margin: "12px 0 24px",
            fontWeight: "600",
            fontStyle: "italic",
          }}
        >
          All prices include GST
        </MDBox>
        <Grid container rowGap={3} justifyContent="center" mx="auto" maxWidth="1400px">
          <Grid item xs={12} lg={6} pr={{ xs: 0, lg: 12 / 8 }}>
            <DefaultPricingCard
              landingPage
              color="white"
              badge={{ color: darkMode ? "warning" : "light", label: "Basic" }}
              price={{ currency: "$", value: isMonthly ? 79 : 63, type: "mo" }}
              specifications={isMonthly ? monthlyBasic : yearlyBasic}
              onClick={() => navigate("/sign-up")}
              action={{
                type: "internal",
                route: "/",
                color: "green",
                label: "Join now",
              }}
              isMonthly={isMonthly}
              shadow={darkMode}
            />
          </Grid>

          <Grid item xs={12} lg={6} pl={{ xs: 0, lg: 12 / 8 }}>
            <DefaultPricingCard
              landingPage
              color="white"
              badge={{ color: darkMode ? "dark" : "light", label: "Deal Maker" }}
              price={{ currency: "$", value: isMonthly ? 199 : 159, type: "mo" }}
              specifications={isMonthly ? monthlyDeal : yearlyDeal}
              action={{
                type: "internal",
                route: "",
                color: "green",
                label: "Join now",
              }}
              isMonthly={isMonthly}
              onClick={() => navigate("/sign-up")}
              shadow={darkMode}
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDTypography
        color="#282828"
        fontWeight="regular"
        fontSize="18px"
        spacing="0.09px"
        marginTop={isMobile ? "72px" : "66px"}
        textAlign="center"
      >
        Brought to you by
      </MDTypography>
      <MDBox width="100%" display="flex" justifyContent="center" my="24px" pb={isMobile ? 5 : 0}>
        <MDBox
          component="img"
          src={FooterImage}
          width="390px"
          height="80px"
          sx={({ breakpoints }) => ({
            [breakpoints.up("xs")]: {
              width: "80%",
            },
          })}
        />
      </MDBox>
    </Grid>
  );
}

export default Pricing;
